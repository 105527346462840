import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
  const [formData, setFormData] = useState({
    tcno: '',
    name: '',
    surname: '',
    birthyear: ''
  });

  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (Object.values(formData).some(value => value === '')) {
      setNotification('Lütfen tüm alanları doldurunuz.');
      return;
    }
  
    if (formData.tcno.length !== 11) {
      setNotification('TC Kimlik No 11 basamaklı olmalıdır.');
      return;
    }
  
    const nameParts = formData.name.split(' ');
    const surnameParts = formData.surname.split(' ');
  
    if (nameParts.length === 1 && nameParts[0].trim() === '') {
      setNotification('İsim alanı boş bırakılamaz.');
      return;
    } else if (nameParts.length >= 2) {
      if (nameParts[0].trim() === '' || nameParts[nameParts.length - 1].trim() === '') {
        setNotification('İsim alanı başında veya sonunda boşluk içeremez.');
        return;
      }
    }
  
    if (surnameParts.length === 1 && surnameParts[0].trim() === '') {
      setNotification('Soyisim alanı boş bırakılamaz.');
      return;
    } else if (surnameParts.length >= 2) {
      if (surnameParts[0].trim() === '' || surnameParts[surnameParts.length - 1].trim() === '') {
        setNotification('Soyisim alanı başında veya sonunda boşluk içeremez.');
        return;
      }
    }
  
    setLoading(true);
    try {
      const response = await axios.post('https://api.antikbet.dev:2100/verifyUser/', formData);
      if (response.data.Result.Sonuc) {
        const userData = `
              <p>TC:<b> ${formData.tcno}</b></p>
              <p>Ad:<b>  ${formData.name}</b></p>
              <p>Soyad:<b>  ${formData.surname}</b></p>
              <p>Doğum Yılı:<b>  ${formData.birthyear}</b></p>
            `;
        setUserInfo(userData);
        setNotification(`${response.data.Result.Mesaj}`);
      } else {
        setNotification(response.data.Result.Mesaj);
      }
    } catch (error) {
      console.error('Form gönderilirken bir hata oluştu:', error);
      setNotification('Form gönderilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };
  
  
  
  

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <h1 className="form-title">Kimlik Doğrulama</h1>
      <input
        type="text"
        name="tcno"
        placeholder="TC Kimlik No"
        value={formData.tcno}
        onChange={handleChange}
      />
      <input
        type="text"
        name="name"
        placeholder="Ad"
        value={formData.name}
        onChange={handleChange}
      />
      <input
        type="text"
        name="surname"
        placeholder="Soyad"
        value={formData.surname}
        onChange={handleChange}
      />
      <input
        type="text"
        name="birthyear"
        placeholder="Doğum Yılı"
        value={formData.birthyear}
        onChange={handleChange}
      />
      <button type="submit" disabled={loading}>{loading ? 'Gönderiliyor...' : 'Doğrula'}</button>

      {notification && (
        <div className={`notification ${notification.includes('başarılı bir şekilde') ? 'success' : 'error'}`}>
          {notification}
        </div>
      )}

      {userInfo && (
        <div className="notification success" dangerouslySetInnerHTML={{ __html: userInfo }}></div>
      )}

    </form>
  );
};

export default Form;
